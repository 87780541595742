export const dateEntries = [
  {
    year: 2024,
    events: [
      { month: 'Dec', event: 'Renate', location: 'Berlin, DE' },
      { month: 'Aug', event: 'Riviera', location: 'Malmöe, SW' }
    ]
  },
  {
    year: 2023,
    events: [
      { month: 'Nov', event: 'Watergate', location: 'Berlin, DE' },
      { month: 'Oct', event: 'Crack Bellmer', location: 'Berlin, DE' },
      { month: 'Oct', event: 'Bus Terraza', location: 'Barcelona, SP' },
      { month: 'Jul', event: 'Watergate', location: 'Berlin, DE' },
      { month: 'May', event: 'Radiant Act', location: 'Château de Marcoux, FR' },
      { month: 'May', event: 'Le Groom', location: 'Lyon, FR' },
      { month: 'Apr', event: 'Crack Bellmer', location: 'Berlin, DE' },
      { month: 'Apr', event: 'Zur Klappe', location: 'Berlin, DE' },
      { month: 'Mar', event: 'Watergate', location: 'Berlin, DE' },
      { month: 'Mar', event: 'Club Opera', location: 'Morzine, FR' }
    ]
  },
  {
    year: 2022,
    events: [
      { month: 'Dec', event: 'Watergate', location: 'Berlin, DE' },
      { month: 'Oct', event: 'Crack Bellmer', location: 'Berlin, DE' },
      { month: 'Aug', event: 'Watergate', location: 'Berlin DE' },
      { month: 'Jul', event: 'Café Borely', location: 'Marseille, FR' },
      { month: 'Jun', event: 'ÆDEN', location: 'Berlin DE' },
      { month: 'May', event: 'Radiant Act', location: 'Château de Buoux, FR' }
    ]
  },
  {
    year: 2021,
    events: [
      { month: 'Dec', event: 'HVLV', location: 'Kyiv, UA' },
      { month: 'Nov', event: 'Christa Kupfer', location: 'Berlin, DE' },
      { month: 'Sep', event: 'Crack Bellmer', location: 'Berlin, DE' }
    ]
  }
];
